import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About me</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
Growing up in Enschede in the east of the Netherlands I felt pretty early on I wanted to travel and see more than just my birth city.
After my "VWO" I went on to study Electronic Engineering & Telecom. Following my studies I moved to Sweden at the age of 21. From living with my parents, to a working guy living on his own abroad! But it turned out I'd live in Sweden for 18 years! It's safe to say Sweden was an important element in my life, but in 2018 it was time to move on.
          </p>
          <p>
After 18 years in Sweden a new phase of our lives started as we moved to The Netherlands. I built this website more to figure out how serverless static site production (jamstack, it's awesome, check it out) worked than to tell the story of my life, so here we go I guess: A summary of my journey in live so far...
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
I moved to Sweden to start work in a company called <b><a href="http://www.projectplace.com">Projectplace</a></b>. Here I worked for seven years in everything from support, sales, account management and then to my main area of interest: IT. My journey at Projectplace ended as the guy responsible for all internal IT and as part of the operations team keeping the SaaS online. A good learning period, both professionally as well as personally. I learned living abroad, met interesting people and learned Swedish in this period.
          </p>
          <p>
I moved on to <b><a href="http://www.tele2.com">Tele2</a></b>, to work as a Systems Engineer VoIP. I worked on the SIP Trunking platform for enterprise customers, fully built in opensource (OpenSER, Asterisk, FreeSwitch, etc). I also worked on in-house development of anti-spam for the mail platform (in use by 20M customers), consumer VoIP services, DNS platform, F5 loadbalancing and network (Cisco) infrastructure. Great stuff, I liked working for an ISP! Design, implementation and hands-on work on a large scale network. Also learned to know some of my best friends in this company.
          </p>
<p>
Next came <b><a href="http://www.klarna.com">Klarna</a></b>, a super fast growing startup in the fintech sector. In the years I worked here as a Tech Lead networking infra, I designed and implemented virtually everything that had to do with infra: office networking, WAN, data center infra, Internet service delivery, CDN's and in the last phase also public cloud design for AWS deployments. High tempo, lots of energy and milestones, structured everything from network spaghetti to documented designs: awesome! Learned a lot on growing a team of infra engineers, agile working in a startup with a high tempo, picking up new tech super quick, etc etc.
</p>
<p>
And now for something completely different: working for semi-government in the <b><a href="http://www.svt.se">Swedish national broadcaster</a></b>. Here I repeated the trick I did at Klarna and in under two years designed, procured and build a new WAN (34 PoP's, our own MPLS network used for remote productions and broadcasting), new office networking (Cisco SDA) and two new DC's (Cisco ACI). Cool new tech all the way from procurement via PoC and labs to production. Great team, I had a good time. With few people and a pretty "government-style" organisation we produced an enourmous amount of change with hard work from the whole team. Learned a lot about broadcasting and (remote) tv production as well, super interesting!
</p>
<p>
The job I moved to The Netherlands for is my current job: Enterprise Architect Infra for the largest bank in NL: <b><a href="http://www.ing.com">ING</a></b>. This job is more high level, setting strategy, vision and design for both cloud / infra as well as investment related platforms. I work on building infra for new banks abroad as well as helping existing banks (ING is active in 15 countries) with their infrastructure journey. Lately I've been focussed on building retail investment products for global use. Remote advice for retail banking customers to make life easier in the complex world of investment products. Quite a stretch from infrastructure, but I love to push myself into new areas and become specialised! A lot of work, travel and learning. Next to the infra and investment platforms, I'm also working as a "normal" Enterprise Architect, trying to fit all IT systems of 15 banks into my head, helping platform development on a global scale.
</p>
<p>
During all this time I ran my own companies "on the side", at least most of the time. From selling mobile phones (first online mobile phone shop in NL that sold phones without contracts), smart cards, satellite receivers (importer for NL for Dreambox!), gameservers (ClanHome!), webhosting, data center consulting and colocation and radar detectors for cars: I did it all! Last thing I did was my precious baby called <b><a href="http://www.satrent.se">SatRent.se</a></b>. I rented out satellite phones in Sweden, for those customers that travelled north to do fishing the wilderness and needed an emergency phone. Learned a lot about the online rental business, payment processing, checkout procedures and satellite tech. 
</p>
          {close}
        </article>

        <article
          id="family"
          className={`${this.props.article === 'family' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Family</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
One thing Sweden actually gave me was the girl I was looking for. We're both not originally from Sweden, so living in neutral territory was pretty good. We got married on Aruba, but have Swedish viking style wedding rings. I guess we like symbolism.
A while later (2012 and 2015) we were lucky enough to get two great kids: Madeleine and Benjamin. It's been super busy since the 10th of April of 2012 one could say. Within Sweden we moved from Stockholm (Nacka) to Uppsala in order to get a a bigger house, but it wasn't really what we expected in many ways... In 2018 we moved to the Netherlands after a long period of thinking, analyzing and planning. In the Netherlands we started out in Amsterdan and finally landed in Haarlem (great city!), where we currently live.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <p>
          If you want to contact me, you can do that here. I'm not into social media much, but some other way of contacting me you find below as well...
          </p>
          <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" name="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" name="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href=" https://blog.vleij.com/"
                className="icon fa-wrench"
              >
                <span className="label">My Blog</span>
              </a>
            </li>
            <li>
              <a
                href=" https://www.linkedin.com/in/robin-vleij-48a668/"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/Zpeed"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
